import { WashListBtn } from "component/wash/item";
import { comFormat, productCaption } from "js/function";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay} from "swiper/modules";

//메인 상단 비디오
function MainVideo(data){
    return (
        <div className={`mainVideoBox ${data.fullSize ? "fullSize" : ""}`}>
            <video muted playsInline autoPlay loop width="100%" height="auto">
				<source src={data.src} type="video/mp4"/>
			</video>
        </div>
    ); 
}

function MainTitle(data){
    return (
        <div className={`maintitleBox`}>
            <h1 className="mainTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
        </div>
    ); 
}

function MainSubTitle(data){
    return (
        <div className={`mainSubTitleBox`}>
            <h1 className="mainSubTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
        </div>
    ); 
}

function MainSection(data){

    return (
        <div className="mainSection">
            {data.children}
        </div>
    );
}

function WaitingTimeBanner(data){
    return (
        <button type="button" onClick={() => {
            data.func()
        }} className={`waitingTimeBanner ${data.businessTimeCheck == 1 ? "" : "notTime"}`}>
            <div className="waitingTimeBanner_textBox">
                <div className="waitingTimeBanner_titleInfo">
                    <div className="waitingTimeBanner_imgBox">
                        <img src="/assets/images/banner/car_img.png" className="carImg"/>
                        <div className="loading_iconBox">
                            <img src="/assets/images/banner/loading_box.png" className="loading_box"/>
                            <img src={`/assets/images/banner/loading_icon${data.businessTimeCheck == 1 ? "" : "_r"}.svg`}
                                className="loading_icon"/>
                        </div>
                    </div>
                    <div className="waitingTimeBanner_titleBox">
                        <h2 className="waitingTimeBanner_subTitle" dangerouslySetInnerHTML={{__html: data.subTitle}}/>
                        <h1 className="waitingTimeBanner_title" dangerouslySetInnerHTML={{__html: data.title}}/>
                    </div>
                </div>
                <img src="/assets/images/banner/banner_btn_move.svg" className="waitingTimeBanner_btn"/>
            </div>
            <div className="waitingTimeBanner_tableBox">
                <table className="waitingTimeBanner_table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>평일</th>
                            <th>주말 및 공휴일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>외부세차</td>
                            <td>09:00 ~ 20:00</td>
                            <td rowSpan={2} className="lastTd">09:00 ~ 19:00</td>
                        </tr>
                        <tr className="lastMainTr">
                            <td>실내세차</td>
                            <td>10:00 ~ 20:00</td>
                        </tr>
                        <tr className="breakTimeBox">
                            <td>휴게시간</td>
                            <td>14:00 ~ 14:30</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </button>
    );
}

function MainMenuItem(data) {
    return (
        <button type="button" onClick={() => {
            data.func()
        }} className={`mainMenuItem`}>
            <div className="mainMenuItem_imgBox">
                {data.img ? <img src={data.img}/> : ""}
            </div>
            <p className="mainMenuItem_text">{data.text}</p>
        </button>
    ); 
}

function MainMenuBox(data){

    return (
        <div className="mainMenuBox">
            {data.children}
        </div>
    );
}

function MainGuide(data){

    return (
        <div className="mainGuideBox">
            {data.data.map((item,i) => (
                <div key={i} className="mainGuideItem">
                    <p className="mainGuideItem_number">{i + 1}</p>
                    <div className="mainGuideItem_textBox">
                        <h1 className="mainGuideItem_title" dangerouslySetInnerHTML={{__html:item.title}}/>
                        <p className="mainGuideItem_text" dangerouslySetInnerHTML={{__html:item.text}}/>
                    </div>
                </div>
            ))}
        </div>
    );
}

function MainPopupTitleBox(data){

    return (
        <div className={`mainPopupTitleBox ${data.addClass||""}`}>
            <h1 className="mainPopupTitle">
                <img src={`/assets/images/icon/${data.addClass == "bColor" ? "time_gy" : "time_g"}.svg`}/>예상 대기시간
            </h1>
            <p className="mainPopupTitle_caption"><b dangerouslySetInnerHTML={{__html:data.caption}}/> 남음</p>
        </div>
    );
}

function MainPopupContentsBox(data){

    return (
        <div className="mainPopupContentsBox">
            {data.children}
        </div>
    );
}

function MainPopupBottomBox(data){

    return (
        <div className="mainPopupBottomBox">
            {data.children}
        </div>
    );
}

function MainPopupContentsTitleBox(data){

    return (
        <div className="mainPopupContentsTitleBox">
            <p className="mainPopupContentsTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
        </div>
    );
}

function MainPopupProductBox(data){

    return (
        <div className="mainPopupProductBox">
            <div className="mainPopupProduct_iconBox">
                <img src="/assets/images/app/logo_b.svg"/>
                {/* <p className="mainPopupProduct_iconCaption">{productCaption(data.name)}</p> */}
            </div>
            <div className="mainPopupProduct_infoBox">
                <p className="mainPopupProduct_info" dangerouslySetInnerHTML={{__html:data.info}}/>
                <h2 className="mainPopupProduct_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            </div>
        </div>
    );
}

function MainPopupReProductBox(data){
    let navigate = useNavigate();

    return (
        <div className="mainPopupReProductBox">
            <MainPopupProductBox
                info={data.info}
                name={data.name}
            />

            <WashListBtn
                addClass=""
                text={`매 월 ${data.price ? `${comFormat(data.price)}원 ` : ""}구독`}
                func={()=>{
                    if(data.id){
                        navigate(`/wash/payment/${data.id}/month`)
                    }else{
                        navigate("/pageErr")
                    }
                }}
            />
        </div>
    );
}

function MainPopupSubContents(data){

    return (
        <div className="mainPopupSubContents">
            {data.icon ? <img src={`/assets/images/icon/${data.icon}.svg`}/> : ""}
            <h1 className="mainPopupSubContents_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="mainPopupSubContents_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function NoticePopupTitleBox(data){
    return (
        <div className="notice_titleBox">
            <div className="notice_titleItem">
                <Swiper
                    spaceBetween={15}
                    slidesPerView={1}
                    autoHeight={true}
                    loop={true}
                    direction="vertical"
                    autoplay={{delay: 3000, disableOnInteraction: false}}
                    modules={[Autoplay]}
                >
                    {data && data?.datas?.map((notice, i) => (
                        <SwiperSlide key={i}>
                            <a href={`/notice/detail/${notice?.id}`}>
                                <div className={`mainPopupTitleBox ${data.addClass||""}`}>
                                        <h1 className="mainPopupTitle">
                                            <div className="notice_badge">
                                                공지
                                            </div>
                                            <div className="notice_title">
                                                {notice?.title}
                                            </div>
                                        </h1>
                                    <p className="mainPopupTitle_caption">
                                        <img src={`/assets/images/icon/right_arrow.svg`}/>
                                    </p>
                                </div>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}


export {MainVideo,MainTitle,MainSubTitle,MainSection,WaitingTimeBanner,MainMenuItem,MainMenuBox,MainGuide,MainPopupTitleBox,MainPopupContentsBox,MainPopupBottomBox,MainPopupContentsTitleBox,MainPopupProductBox,MainPopupReProductBox,MainPopupSubContents,NoticePopupTitleBox};